import React, {Fragment, useState, useRef, useEffect} from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import {useForm} from 'react-hook-form'

const defaultStyle = {
    display: 'block',
    overflow: 'hidden',
    resize: 'none',
    width: '100%',
    backgroundColor: 'mediumSpringGreen'
}

export default function EmailForm(props) {
    const EMAIL_STATUS = {None: 0, Sending: 1, Sent: 2, Error: 3}
    const translations = {
        sv: {
            emailPlaceholder: 'Din epostadress',
            emailError: 'Giltig epostadress och innehåll krävs',
            sendButton: 'Skicka',
            emailSending: 'Skickar...',
            emailSendingError: 'Någonting gick fel! Vänligen skicka email till info@cppgarments.com.',
            emailSent: 'Ditt meddelande har skickats',
        },
        en: {
            emailPlaceholder: 'Your email',
            emailError: 'A valid email address and message content are required',
            sendButton: 'Send',
            emailSending: 'Sending...',
            emailSendingError: 'Something went wrong! Please email info@cppgarments.com.',
            emailSent: 'Your message has been sent',
        },
        de: {
            emailPlaceholder: 'Ihre Mailadresse',
            emailError: 'Eine gültige Mailadresse und Nachrichtentext sind erforderlich',
            sendButton: 'Senden',
            emailSending: 'Wird gesendet...',
            emailSendingError: 'Etwas ist schief gelaufen! Bitte senden Sie eine E-Mail an info@cppgarments.com.',
            emailSent: 'Ihre Nachricht wurde gesendet',
        },
        fr: {
            emailPlaceholder: 'Votre email',
            emailError: 'Une adresse email valide et un contenu de message sont requis',
            sendButton: 'Envoyer',
            emailSending: 'Envoi...',
            emailSendingError: 'Quelque chose s&#39;est mal passé! Veuillez envoyer un email à info@cppgarments.com.',
            emailSent: 'Votre message a été envoyé',
        },
    }
    const contactReasons = {
        sv: {
            requestSuitInfo: 'Jag vill veta mer om skyddsunderstället',
            requestQuote: 'Hur mycket kostar skyddsunderstället?',
            requestPdf: 'Jag vill att ni mejlar en ensidig pdf med huvudsakliga egenskaper',
            requestCall: 'Jag vill att ni ringer upp mig. Skriv ditt telenr i rutan nedan',
            requestMeeting: 'Jag vill att ni återkommer för att boka ett videomöte',
            requestReports: 'Jag vill ha ett mejl med alla de viktigaste rapporterna och studierna',
            requestOther: 'Övrigt. Skriv i fritext i rutan nedan'
        },
        en: {
            requestSuitInfo: 'I want more information about the protective undergarment',
            requestQuote: 'How much does the protective undergarment cost?',
            requestPdf: 'Please email me a single page pdf describing the main features of the undergarment',
            requestCall: 'Please call me! Enter your phone number in the text box below',
            requestMeeting: 'Please get back to me to schedule a video meeting!',
            requestReports: 'Please email me the most important reports and studies',
            requestOther: 'Other inquiry, please specify in the text box below'
        },
        de: {
            requestSuitInfo: 'Ich möchte mehr Informationen über die Schutzunterbekleidung erhalten',
            requestQuote: 'Ich möchte Informationen über die Preise der Schutzunterbekleidung erhalten',
            requestPdf: 'Bitte senden Sie mir eine PDF-Datei mit einer Beschreibung der wichtigsten Merkmale der Schutzunterbekleidung per E-Mail',
            requestCall: 'Bitte rufen Sie mich an! Geben Sie dazu Ihre Telefonnummer in das untenstehende Textfeld ein',
            requestMeeting: 'Bitte setzen Sie sich mit mir in Verbindung, um ein Videomeeting zu vereinbaren!',
            requestReports: 'Bitte senden Sie mir die wichtigsten Berichte und Studien per E-Mail zu',
            requestOther: 'Bitte tragen Sie andere Anfragen in das Textfeld unten ein'
        },
        fr: {
            requestSuitInfo: 'Je souhaite obtenir plus d\'informations sur le sous-vêtement de protection',
            requestQuote: 'Quel est le prix du sous-vêtement de protection?',
            requestPdf: 'Veuillez m\'envoyer par email un PDF d\'une page décrivant les principales caractéristiques du sous-vêtement.',
            requestCall: 'Appelez-moi! Saisissez votre numéro de téléphone dans la zone de texte ci-dessous.',
            requestMeeting: 'N\'hésitez pas à me contacter pour fixer une réunion vidéo!',
            requestReports: 'Veuillez m\'envoyer par email les rapports et études les plus importants',
            requestOther: 'Autres renseignements, veuillez préciser dans la zone de texte ci-dessous.'
        }
    }


    const t = translations[props.lang]

    const {register, handleSubmit, errors} = useForm()
    const [emailStatus, setEmailStatus] = useState(EMAIL_STATUS.None)

    const [emailText, setEmailText] = useState('')
    const textAreaRef = useRef()
    useEffect(() => {
        textAreaRef.current.style.height = '0px'
        const scrollHeight = Math.max(textAreaRef.current.scrollHeight, 90)
        textAreaRef.current.style.height = scrollHeight + 'px'
    }, [emailText])

    const onSubmit = (data) => {
        data.lang = props.lang
        setEmailStatus(EMAIL_STATUS.Sending)
        axios
            .post('/api/email', data)
            .then((response) => {
                setEmailStatus(EMAIL_STATUS.Sent)
            })
            .catch((error) => {
                setEmailStatus(EMAIL_STATUS.Error)
            })
        // console.log(data)
    }

    // const radioBoxes = Object.values(questions[props.lang]).map(p => <input type="radio">{p}</input>)
    const radioBoxes = Object.entries(contactReasons[props.lang]).map(([k, v], i) => (
        <div className="form-check" key={k}>
            <input className="form-check-input" ref={register({required: true})} type="radio" name="contactReasons"
                   id={k} value={v}/>
            <label className="form-check-label" htmlFor={k}>{v}</label>
        </div>
    ))

    const emailForm = () => (
        <form onSubmit={handleSubmit(onSubmit)} method="POST">
            <div className="form-group">
                <input
                    type="text"
                    ref={register({required: true})}
                    name="email"
                    placeholder={t.emailPlaceholder}
                    className="form-control"
                />
            </div>
            <div className="form-group text-left px-2">
                {radioBoxes}
            </div>
            <div className="form-group">
                <textarea
                    ref={(e) => {
                        register({required: true})(e)
                        textAreaRef.current = e
                    }}
                    name="content"
                    className="email-body form-control"
                    onChange={e => setEmailText(e.target.value)}
                />
            </div>
            {(errors.email || errors.content) && <p className="text-danger">{t.emailError}</p>}
            <div className="form-group">
                <input type="submit" value={t.sendButton} className="btn btn-primary"/>
            </div>
        </form>
    )

    return (
        <Fragment>
            {emailStatus === EMAIL_STATUS.None ? emailForm() : null}
            {emailStatus === EMAIL_STATUS.Sending ? (
                <p className="text-center email-sending">
                    <i className="icon-spinner animate-spin"/>
                    {t.emailSending}
                </p>
            ) : null}
            {emailStatus === EMAIL_STATUS.Sent ? (
                <p className="text-center email-sent">{t.emailSent}</p>
            ) : null}
            {emailStatus === EMAIL_STATUS.Error ? (
                <p className="text-center text-danger">{t.emailSendingError}</p>
            ) : null}
        </Fragment>
    )
}

document.addEventListener('turbolinks:load', () => {
    const el = document.getElementById('emailFormContainer')
    if (el)
        ReactDOM.render(
            <EmailForm lang={el.dataset.lang}/>,
            el
        )
})

